<template>
  <!-- Header -->
  <welcome-header/>

  <!-- Slides -->
  <welcome-slides />

  <!-- App Store -->
  <welcome-mobile-stores />

</template>

<script>

import WelcomeHeader from "@/views/Welcome/partials/WelcomeHeader";
import WelcomeSlides from "@/views/Welcome/partials/WelcomeSlides";
import WelcomeMobileStores from "@/views/Welcome/partials/WelcomeMobileStores";
export default {
  name: "Welcome",
  components: {WelcomeMobileStores, WelcomeSlides, WelcomeHeader},
}
</script>

<style lang="scss" scoped>

</style>
