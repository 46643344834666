<template>
  <!--  Header  -->
  <div class="guest-address-header">
    <div class="container Aligner">
      <!--    Nav Menu    -->
      <div class="nav-menu">
        <div class="row justify-content-between">
          <div class="column col-auto">
            <img class="logo" src="@/assets/logo-white.png" alt="Logo"/>
          </div>
          <div class="column col-auto">
            <router-link to='/login' class="btn btn-white text-transform-none">
              {{ $t("auth.login.label") }} / {{ $t("auth.register.label") }}
            </router-link>
          </div>
        </div>
      </div>

      <!--    Header body    -->
      <div class="body text-center">
        <div class="title">
          {{ $t("welcome.header.title") }}
        </div>
        <div class="subtitle font-size-xl my-5">
          {{ $t("welcome.header.subtitle") }}
        </div>
        <div class="search">
          <gmaps-search-place @addressChanged="addressChanged"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import GmapsSearchPlace from "@/views/Welcome/partials/GmapsSearchPlace";

export default {
  name: "WelcomeHeader",
  components: {GmapsSearchPlace},
  data() {
    return {
      newGuestAddress: {
        "postal_code": null,
        "latitude": null,
        "longitude": null,
        "address_description": null,
        "country": null,
        "state": null,
        "city": null
      }
    }
  },
  computed: {
    ...mapGetters(['guestAddress'])
  },
  methods: {
    ...mapActions(['setGuestAddress']),
    addressChanged(newAddress) {
      //Update local storage
      let address = {};
      if (newAddress.geometry !== undefined) {
        address = {
          'latitude': newAddress.geometry.location.lat(),
          'longitude': newAddress.geometry.location.lng(),
          'ready': false
        };
      } else {
        address = {
          'latitude': newAddress.latitude,
          'longitude': newAddress.longitude,
          'ready': false
        };
      }

      this.setGuestAddress(address).then(() => {
        this.$router.push('/guest-address/create');
      });

    },
  }
}
</script>

<style lang="scss" scoped>
$headerNav_size: 100px;

.guest-address-header {
  width: 100%;
  height: 100vh;
  background-image: url(~@/assets/guest-address/bg-desktop.png);
  background-position: center;
  background-size: cover;
  position: relative;
  color: map-get($colors, "white");

  &:before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(31, 33, 0, .4);
  }

  .container {
    height: 100%;
    position: relative;

    .nav-menu {
      line-height: $headerNav_size;
      position: absolute;
      top: 0;
      left: map-get($spacings, "default");
      right: map-get($spacings, "default");

      .logo {
        height: 35px;
      }
    }

    & > .body {
      padding-top: $headerNav_size;
      max-width: 960px;

      .title {
        font-size: 56px;
        line-height: 1.2em;
      }

      .search {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .guest-address-header {
    background-image: url(~@/assets/guest-address/bg-mobile.png);
    background-position: center;
    background-size: auto 100%;
    .container {
      & > .body {
        .title {
          font-weight: bold;
          font-size: 26px;
          line-height: 1.4em;
        }
      }
    }
  }
}
</style>
