<template>
  <div class="guest-address-mobile-stores">
    <div class="container">
      <div class="row">
        <div class="col-md-6 left-side Aligner">
          <img src="@/assets/guest-address/mobile-app-preview.png" alt="">
        </div>
        <div class="col-md-6 right-side Aligner">
          <div class="centered">
            <div class="title">
              {{ $t('welcome.mobile-stores.title') }}
            </div>
            <div class="subtitle font-size-lg my-4">
              {{ $t('welcome.mobile-stores.subtitle') }}
            </div>

            <div class="app-stores row">
              <div class="google-play col-6" v-if="playStoreLink">
                <a :href="playStoreLink">
                  <img src="@/assets/app-stores/google_play.png" alt="">
                </a>

              </div>
              <div class="app-store col-6" v-if="appStoreLink">
                <a :href="appStoreLink">
                  <img src="@/assets/app-stores/app_store.png" alt="">
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomeMobileStores",
  data() {
    return {
      playStoreLink: process.env.VUE_APP_PLAY_STORE_LINK,
      appStoreLink: process.env.VUE_APP_APP_STORE_LINK
    }
  }
}
</script>

<style lang="scss" scoped>
.guest-address-mobile-stores {
  background-color: map-get($colors, "twiks");
  padding-top:100px;
  padding-bottom:100px;
  color:map-get($colors,"white");


  .left-side {
    img {
      width:100%;
      max-width:550px;
    }
  }

  .right-side {
    .centered {
      max-width:400px;
      text-align: center;
      .title {
        font-size: 56px;
        line-height: 1.2em;
        font-weight:bold;
      }

      .app-stores {
        img {
          width:100%;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .guest-address-mobile-stores {
    .left-side {
      img {
        max-width:250px;
      }
    }

    .right-side {
      .centered {
        .title {
          font-size: 26px;
          line-height: 1.4em;
        }

        .app-stores {
          text-align: center;
          img {
            width:100%;
            max-width: 80%;
          }
        }
      }
    }
  }
}
</style>
