<template>
  <div class="guest-address-slides">
    <div class="container">
      <div class="row">
        <div class="column col-md-4">
          <div class="image ratio ratio-4_3">
            <div class="body">
              <img src="@/assets/auth/slides/slide-1.svg" alt="">
            </div>
          </div>
          <div class="body">
            <p class="font-size-xl">{{ $t("auth.slides.slide1.heading") }}</p>
            <p class="color-secondary"> {{ $t("auth.slides.slide1.body") }} </p>
          </div>
        </div>

        <div class="column col-md-4">
          <div class="image ratio ratio-4_3">
            <div class="body">
              <img src="@/assets/auth/slides/slide-2.svg" alt="">
            </div>
          </div>
          <div class="body">
            <p class="font-size-xl">{{ $t("auth.slides.slide2.heading") }}</p>
            <p class="color-secondary"> {{ $t("auth.slides.slide2.body") }} </p>
          </div>
        </div>

        <div class="column col-md-4">
          <div class="image ratio ratio-4_3">
            <div class="body">
              <img src="@/assets/auth/slides/slide-3.svg" alt="">
            </div>
          </div>
          <div class="body">
            <p class="font-size-xl">{{ $t("auth.slides.slide3.heading") }}</p>
            <p class="color-secondary"> {{ $t("auth.slides.slide3.body") }} </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomeSlides"
}
</script>

<style lang="scss" scoped>
  .guest-address-slides {
    margin-top: 100px;
    margin-bottom: 100px;

    .column {
      & > .image {
        text-align: center;
        img {
          width:auto;
          min-height:100%;
          max-height: 100%;
          max-width:250px;
        }
      }

      & > .body {
        margin-top: map-get($spacings, "xxl");
        text-align: center;

        p {
          margin-bottom: map-get($spacings,"small");

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
</style>
