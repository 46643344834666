<template>
  <div class="row">
    <div class="col-12">

      <div class="autocomplete-input" :class="{loading: gps_loading}">
        <input class='form-control' ref="placesInput" id='places-autocomplete' type="text" :placeholder="$t('welcome.gmaps-search.input.placeholder')">
        <div class="getByGPS" @click="getGPSCoordinates">
          <loading :loading="gps_loading">
            <i v-if="gps_permission_denied" class="fas fa-exclamation-triangle"></i>
            <div v-else class="icon"></div>
          </loading>
          <div class="body">
            <div>{{ $t('welcome.gmaps-search.gps-btn.line-1') }}</div>
            <div>{{ gps_permission_denied ? $t('welcome.gmaps-search.gps-btn.gps_permission_denied') : $t('welcome.gmaps-search.gps-btn.line-2') }}</div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import Loading from "@/components/Loading";
import {ref} from "vue";

export default {
  name: "GmapsSearchPlace",
  setup() {
    const placesInput = ref(null);

    return {
      placesInput,
    };
  },
  components: {Loading},
  data() {
    return {
      gps_permission_denied: false,
      gps_loading: false,
      autocomplete: null,
      autocompleteService: null,
      place: null
    }
  },
  watch: {
    query(newValue) {
      this.searchPlace(newValue);
    }
  },
  methods: {
    getGPSCoordinates() {
      this.gps_loading = true;
      this.$getLocation().then((coordinates) => {
        this.gps_permission_denied = false;
        this.place = {};
        this.place.latitude = coordinates.lat;
        this.place.longitude = coordinates.lng;

        this.$emit("addressChanged", this.place);
        this.gps_loading = false;
      }).catch(() => {
        this.gps_permission_denied = true;
        this.gps_loading = false;
      }).finally(() => {
        this.placesInput.value = "";
      });
    },
    initAutocomplete() {
      const options = {
        strictBounds: false,
        fields: ["address_components", "formatted_address", "geometry", "name"],
      };
      this.autocomplete = new window.google.maps.places.Autocomplete(document.getElementById("places-autocomplete"), options);

      this.autocomplete.addListener("place_changed", () => {
        const place = this.autocomplete.getPlace();

        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }

        this.place = place;
        this.$emit("addressChanged", this.place);
      });
    }
  },
  mounted() {
    setTimeout(() => {
      this.initAutocomplete();
    }, 2000);
  }
}
</script>

<style lang="scss">
.autocomplete-input {
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    right: map-get($spacings,"default");
    width:20px;
    height:20px;
    background-image: url("~@/assets/arrow_down.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  #places-autocomplete {
    width: 100%;
    background-image: url("~@/assets/marker.svg");
    background-size: auto 18px;
    background-position: left map-get($spacings, "default") center;
    background-repeat: no-repeat;
    padding-left: 40px;
    padding-right: 40px;
    line-height: 38px;

    &:active + div, &:focus + div {
      display: block;
    }
  }

  .getByGPS {
    width: 100%;
    border-radius: map-get($defaults, "border-radius");
    color: map-get($colors, 'black');
    background-color: #fff;
    text-align: left;
    margin-top: map-get($spacings, 'xsmall');
    padding: 7px map-get($spacings, "default");
    z-index: 1001;
    display: none;
    position: absolute;
    top: 100%;
    left:0;


    &:hover {
      cursor: pointer;
      display: block;
    }

    .icon, .loading, i {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: map-get($spacings, "default");
    }

    i {
      color: map-get($colors, "danger");
    }

    .icon {
      background-image: url("~@/assets/gps.svg");
      background-size: auto 18px;
      background-position: center;
      background-repeat: no-repeat;
    }

    & > .body {
      margin-left: 30px;

      div {
        width: 100%;

        &:nth-child(2) {
          font-size: 11px;
          color: map-get($colors, "secondary");
        }
      }
    }
  }

  &.loading {
    .getByGPS {
      display: block;
    }
  }
}


.pac-container {
  border-radius: map-get($defaults, "border-radius");
  margin-top: 50px;
  position: relative;
  padding: 1px 1px 10px 1px;
  box-sizing: content-box;

  .pac-item {
    padding: 7px map-get($spacings, "default");
    margin-top: map-get($spacings, 'xsmall');
    line-height: 1.4em;
    border-top: 0;
    position: relative;

    .pac-icon {
      width: 20px;
      height: 20px;
      display: block;
      float: left;
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: map-get($spacings, "default");

      background-image: url("~@/assets/marker.svg");
      background-size: auto 18px;
      background-position: center;
      background-repeat: no-repeat;
    }

    .pac-item-query {
      display: block;
      margin-left: 30px;
      font-size: 14px;

      .pac-matched {

      }
    }

    span:nth-child(3) {
      display: block;
      margin-left: 30px;
      color: map-get($colors, "secondary");
    }

    &:hover {
      cursor: pointer;
      background-color: map-get($colors, "lightGray");
    }
  }

  &:after {
    margin-right: map-get($spacings, "default") !important;
  }
}

</style>
